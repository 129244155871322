
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup() {
    const visible = ref<boolean>(false);

    const showModal = () => {
      visible.value = true;
    };

    const handleOk = (e: MouseEvent) => {
      console.log(e);
      visible.value = false;
    };
    return {
      visible,
      showModal,
      handleOk,
    };
  },
  props: {
    youtubeLink: {
      type: String,
      required: true,
    },
  },
});
