import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-6c0ddf36")
const _hoisted_1 = {
  key: 0,
  class: "header"
}
const _hoisted_2 = { class: "position-relative main-header" }
const _hoisted_3 = { class: "container" }
const _hoisted_4 = {
  key: 1,
  class: "fluid-container hide-on-mobile"
}
const _hoisted_5 = {
  key: 2,
  class: "fluid-container hide-on-mobile"
}
const _hoisted_6 = { class: "container-casinos m-0" }
const _hoisted_7 = { class: "container" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "section-title" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  class: "container-news",
  style: {"background-image":"url(https://coinflip.modeltheme.com/wp-content/uploads/2020/04/recommended-bonus-scaled.jpg?id=48283) !important"}
}
const _hoisted_12 = { class: "container" }
const _hoisted_13 = { class: "container-casinos m-0 hide-on-mobile" }
const _hoisted_14 = { class: "container" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_page_anim = _resolveComponent("loading-page-anim")!
  const _component_main_header = _resolveComponent("main-header")!
  const _component_SectionHeading = _resolveComponent("SectionHeading")!
  const _component_SectionHomeBonuses = _resolveComponent("SectionHomeBonuses")!
  const _component_Jumbotron = _resolveComponent("Jumbotron")!
  const _component_SectionWithImage = _resolveComponent("SectionWithImage")!
  const _component_FAQ = _resolveComponent("FAQ")!
  const _component_VideoPopup = _resolveComponent("VideoPopup")!
  const _component_CardCasinoOffer = _resolveComponent("CardCasinoOffer")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_SubtitleSections = _resolveComponent("SubtitleSections")!
  const _component_CardNews = _resolveComponent("CardNews")!
  const _component_CasinoCardLong = _resolveComponent("CasinoCardLong")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_loading_page_anim, {
      loading: _ctx.loading && _ctx.sectionLoading && _ctx.loadingFaq
    }, null, 8, ["loading"]),
    _createVNode(_component_a_layout, null, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout_content, null, {
          default: _withCtx(() => [
            (!_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_main_header, {
                      class: "my-auto main-header",
                      subtitle: "ENTER VIDEO POKER",
                      description: _ctx.header.description,
                      href: _ctx.header.button.href,
                      buttonName: "ALL GAMES",
                      hideImages: true
                    }, null, 8, ["description", "href"])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "container-bonuses",
              style: _normalizeStyle({
          'background-image':
            'url(' +
            require('../assets/images/background/bg_bonuses.jpeg') +
            ')',
        })
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_SectionHomeBonuses, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_SectionHeading, {
                      class: "section-heading",
                      title: "Best Poker Bonuses",
                      subtitle: "Just flip a Coin",
                      description: "At Modeltheme, we show only the best websites and portfolios built completelywith passion, simplicity & creativity !"
                    })
                  ]),
                  _: 1
                })
              ])
            ], 4),
            (!_ctx.sectionLoading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_SectionWithImage, {
                    class: "special-container",
                    thumbnail: {
            url:
              'https://coinflip.modeltheme.com/wp-content/uploads/2020/10/Coinflip-Poker_right.jpg',
          }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Jumbotron, {
                        title: _ctx.section.title,
                        subtitle: _ctx.section.subtitle,
                        description: _ctx.section.description,
                        buttonName: _ctx.section.buttonName,
                        href: 'contact'
                      }, null, 8, ["title", "subtitle", "description", "buttonName"])
                    ]),
                    _: 1
                  }, 8, ["thumbnail"])
                ]))
              : _createCommentVNode("", true),
            (!_ctx.loadingFaq)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_SectionWithImage, {
                    class: "special-container",
                    thumbnail: {
            url:
              'https://coinflip.modeltheme.com/wp-content/uploads/2020/10/Coinflip-Poker_left.jpg',
          },
                    rtl: true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FAQ, {
                        items: _ctx.faq.faqList
                      }, null, 8, ["items"])
                    ]),
                    _: 1
                  }, 8, ["thumbnail"])
                ]))
              : _createCommentVNode("", true),
            _createVNode(_component_VideoPopup, { youtubeLink: "//www.youtube.com/embed/Ue5ght7izjc?autoplay=1" }),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (!_ctx.titleLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_SectionHeading, {
                          class: "section-heading",
                          title: "Top Casinos By Popularity",
                          subtitle: _ctx.titles.section[0].subtitle,
                          description: _ctx.titles.section[0].description
                        }, null, 8, ["subtitle", "description"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.casinosLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      _createVNode(_component_a_row, {
                        justify: "center",
                        type: "flex",
                        gutter: [30, 30]
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.casinos, (item, i) => {
                            return (_openBlock(), _createBlock(_component_a_col, {
                              xl: 6,
                              xs: 24,
                              key: item
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_CardCasinoOffer, {
                                  class: "card-casino",
                                  thumbnail: item.thumbnail,
                                  title: item.title,
                                  description: item.description,
                                  badge: {
                    positionNo: i + 1,
                    type: 'default',
                  },
                                  rating: 
                    item.review === null ? 0 : _ctx.fCalcRating(item.review.rating)
                  ,
                                  href: {
                    name: 'Casino',
                    params: {
                      slug: item.slug,
                    },
                  },
                                  offer: item.termsAndConditions,
                                  reward: item.excerpt
                                }, null, 8, ["thumbnail", "title", "description", "badge", "rating", "href", "offer", "reward"])
                              ]),
                              _: 2
                            }, 1024))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_SubtitleSections, {
                  class: "subtitle text-start",
                  title: "Gambling News",
                  subtitle: "At Coinflip, we show only the best casinos.",
                  href: "/"
                }),
                (!_ctx.loadingNews)
                  ? (_openBlock(), _createBlock(_component_a_row, {
                      key: 0,
                      justify: "center",
                      type: "flex",
                      gutter: [25, 25]
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.latestNews, (item) => {
                          return (_openBlock(), _createBlock(_component_a_col, {
                            xl: 8,
                            xs: 24,
                            key: item
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_CardNews, {
                                class: "card-news mb-1 mb-md-0",
                                title: item.title,
                                thumbnail: item.thumbnail,
                                description: item.description,
                                author: { name: 'Trader Marcus', href: '/' },
                                href: {
                  name: 'Blog',
                  params: {
                    slug: item.slug,
                  },
                }
                              }, null, 8, ["title", "thumbnail", "description", "href"])
                            ]),
                            _: 2
                          }, 1024))
                        }), 128))
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bookmakers, (item) => {
                  return (_openBlock(), _createBlock(_component_CasinoCardLong, _mergeProps({ key: item }, item), null, 16))
                }), 128))
              ])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}