
import { defineComponent, PropType } from "vue";
import { API_CustomEnumeration, Casino, Tag, Thumbnail } from "@/types";
import Rating from "../Rating/Rating.vue";
export default defineComponent({
  components: { Rating },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: Object as PropType<Thumbnail>,
      required: true,
    },
    restrictedCountries: {
      type: Object as PropType<Tag[]>,
      required: false,
    },
    features: {
      type: Array as PropType<API_CustomEnumeration[]>,
    },
    href: {
      type: Object,
    },
    termsAndConditions: {
      type: String,
    },
    casinos: {
      type: Object as PropType<Casino[]>,
    },
    rating: {
      type: Number,
    },
  },
});
