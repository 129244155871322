
import { defineComponent, Ref } from "vue";
import MainHeader from "../components/MainHeader/MainHeader.vue";
import LoadingPageAnim from "../components/LoadingPageAnim.vue";
import fetchHomeHeader from "../misc/composition/fetchHomeHeader";
import SectionWithImage from "../components/SectionWithImage/SectionWithImage.vue";
import SectionHomeBonuses from "../components/SectionHomeBonuses/SectionHomeBonuses.vue";
import Jumbotron from "../components/Jumbotron/Jumbotron.vue";
import fetchSectionWithImage from "../misc/composition/fetchHomSectionWithImage";
import FAQ from "../components/FAQ/FAQ.vue";
import fetchHomeFAQ from "../misc/composition/fetchHomeFAQ";
import SectionHeading from "../components/SectionHeading/SectionHeading.vue";
import VideoPopup from "@/components/VideoPopup/VideoPopup.vue";
import CardCasinoOffer from "../components/CardCasinoOffer/CardCasinoOffer.vue";
import fetchHomeTitles from "../misc/composition/fetchHomeSectionTitles";
import fetchHomeCasinoByID from "../misc/composition/fetchHomeCasinoByID";
import casinoHelpers from "../misc/composition/casinoHelpers";
import { Casino } from "@/types";
import CardNews from "../components/CardNews/CardNews.vue";
import fetchLatestNews from "../misc/composition/fetchLatestNews";
import SubtitleSections from "@/components/SubtitleSections/SubtitleSections.vue";
import CasinoCardLong from "@/components/CasinoCardLong/CasinoCardLong.vue";

export default defineComponent({
  name: "Home",
  components: {
    MainHeader,
    SectionWithImage,
    SectionHomeBonuses,
    CardNews,
    SectionHeading,
    Jumbotron,
    FAQ,
    LoadingPageAnim,
    VideoPopup,
    CardCasinoOffer,
    SubtitleSections,
    CasinoCardLong,
  },
  setup() {
    const { header, loading } = fetchHomeHeader();
    const { section, loading: sectionLoading } = fetchSectionWithImage();
    document.title = "Home Video Poker - Coinflip";

    const { faq, loading: loadingFaq } = fetchHomeFAQ();
    const { casinos, loading: casinosLoading } = fetchHomeCasinoByID();
    const { titles, loading: titleLoading } = fetchHomeTitles();
    const { fCalcRating } = casinoHelpers({} as Ref<Casino>);
    const { latestNews, loading: loadingNews } = fetchLatestNews();

    return {
      latestNews,
      loadingNews,
      casinos,
      loading,
      titleLoading,
      titles,
      fCalcRating,
      casinosLoading,
      header,
      section,
      sectionLoading,
      faq,
      loadingFaq,
    };
  },
  data() {
    return {
      bookmakers: [
        {
          id: 2,
          title: "Bet243",
          excerpt: "New customer offer. Exchange bets excluded.",
          about:
            "About Bookmaker\nLeverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.\n\nBring to the table win-win survival strategies to ensure proactive domination.\nCapitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.Podcasting operational change management inside of workflows to establish a framework.\n\nTaking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.",
          type: "certified",
          likes: 3,
          referralLink: "https://modeltheme.com/go/coinflip/",
          active: true,
          slug: "Bet243",
          review: null,
          published_at: "2021-10-08T12:20:52.025Z",
          created_at: "2021-10-08T12:09:34.216Z",
          updated_at: "2021-11-01T07:49:44.253Z",
          termsAndConditions: "New customer offer. Exchange bets excluded.",
          newaccountoffer: "up to $200  Equal bonus with deposit",
          offerValue: "up to $200",
          offerInfo: "Equal bonus with deposit",
          casinos: [
            {
              thumbnail: {
                url: "images/casino-logos/cat_casino4.jpg",
              },
            },
            {
              thumbnail: {
                url: "images/games/esport-betting-slot3.jpg",
              },
            },
          ],
          rating: 3,
          experiences: [
            {
              id: 149,
              pros: "Unlimited withdraw limits",
              cons: "A number of restricted countries",
            },
            {
              id: 150,
              pros: "Multiple withdraw methods",
              cons: "Slow connection sometimes",
            },
            {
              id: 151,
              pros: "Live chat open 24/7",
              cons: "Long withdraw time",
            },
            {
              id: 152,
              pros: "Mobile-friendly design",
              cons: "Some bugs",
            },
          ],
          socials: [],
          acceptedCountries: [
            {
              id: 96,
              text: "RO",
            },
          ],
          tags: {
            id: 2,
            paymentMethods: [
              {
                id: 10,
                name: "ATM",
                slug: "atm",
                created_at: "2021-10-12T12:50:48.086Z",
                updated_at: "2021-10-12T12:50:48.090Z",
              },
              {
                id: 9,
                name: "Payeer",
                slug: "payeer",
                created_at: "2021-10-12T12:50:41.505Z",
                updated_at: "2021-10-12T12:50:41.510Z",
              },
            ],
            currencies: [
              {
                id: 3,
                name: "EURO",
                code: "EUR",
                slug: "eur",
                created_at: "2021-10-12T12:43:39.667Z",
                updated_at: "2021-10-12T12:43:39.670Z",
              },
              {
                id: 4,
                name: "Canadian Dollar",
                code: "CAD",
                slug: "cad",
                created_at: "2021-10-12T12:43:56.025Z",
                updated_at: "2021-10-12T12:43:56.028Z",
              },
            ],
            languages: [
              {
                id: 2,
                name: "English",
                code: "EN",
                slug: "english",
                created_at: "2021-10-12T12:38:05.543Z",
                updated_at: "2021-10-12T12:38:05.548Z",
              },
              {
                id: 6,
                name: "Italian",
                code: "IT",
                slug: "italian",
                created_at: "2021-10-12T12:39:06.327Z",
                updated_at: "2021-10-12T12:39:06.330Z",
              },
            ],
            licences: [
              {
                id: 7,
                name: "Tortor",
                slug: "tortor",
                created_at: "2021-10-12T12:48:28.833Z",
                updated_at: "2021-10-12T12:48:28.836Z",
              },
            ],
            restrictedCountries: [
              {
                id: 6,
                name: "Colombia",
                slug: "colombia",
                code: "CO",
                created_at: "2021-10-12T13:30:03.330Z",
                updated_at: "2021-10-12T13:30:03.336Z",
              },
            ],
            softwares: [],
            categories: [],
          },
          features: [
            {
              id: 440,
              text: "Excepteur sint occaecat cupidatat",
            },
            {
              id: 442,
              text: "Duis aute irure dolor",
            },
            {
              id: 443,
              text: "Lorem ipsum dolor sit amet, consectetur",
            },
            {
              id: 444,
              text: "Fames ac turpis egestas maecenas",
            },
            {
              id: 441,
              text: "Dignissim cras tincidunt lobortis",
            },
          ],
          thumbnail: {
            id: 40,
            name: "cat_casino26.jpeg",
            alternativeText: "",
            caption: "",
            width: 500,
            height: 500,
            formats: {
              thumbnail: {
                name: "thumbnail_cat_casino26.jpeg",
                hash: "thumbnail_cat_casino26_fe3fd4c902",
                ext: ".jpeg",
                mime: "image/jpeg",
                width: 156,
                height: 156,
                size: 3.56,
                path: null,
                url: "images/casino-logos/cat_casino4.jpg",
              },
            },
            hash: "cat_casino26_fe3fd4c902",
            ext: ".jpeg",
            mime: "image/jpeg",
            size: 18.4,
            url: "images/casino-logos/cat_casino4.jpg",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            created_at: "2021-10-08T11:10:46.848Z",
            updated_at: "2021-10-08T11:10:46.859Z",
          },
          href: {
            name: "Bookmaker",
            params: {
              slug: "/",
            },
          },
        },
        {
          id: 2,
          title: "Bet243",
          excerpt: "New customer offer. Exchange bets excluded.",
          about:
            "About Bookmaker\nLeverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.\n\nBring to the table win-win survival strategies to ensure proactive domination.\nCapitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.Podcasting operational change management inside of workflows to establish a framework.\n\nTaking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.",
          type: "certified",
          likes: 3,
          referralLink: "https://modeltheme.com/go/coinflip/",
          active: true,
          slug: "Bet243",
          review: null,
          published_at: "2021-10-08T12:20:52.025Z",
          created_at: "2021-10-08T12:09:34.216Z",
          updated_at: "2021-11-01T07:49:44.253Z",
          termsAndConditions: "New customer offer. Exchange bets excluded.",
          newaccountoffer: "up to $200  Equal bonus with deposit",
          offerValue: "up to $200",
          offerInfo: "Equal bonus with deposit",
          casinos: [
            {
              thumbnail: {
                url: "images/casino-logos/cat_casino4.jpg",
              },
            },
            {
              thumbnail: {
                url: "images/games/esport-betting-slot3.jpg",
              },
            },
          ],
          rating: 3,
          experiences: [
            {
              id: 149,
              pros: "Unlimited withdraw limits",
              cons: "A number of restricted countries",
            },
            {
              id: 150,
              pros: "Multiple withdraw methods",
              cons: "Slow connection sometimes",
            },
            {
              id: 151,
              pros: "Live chat open 24/7",
              cons: "Long withdraw time",
            },
            {
              id: 152,
              pros: "Mobile-friendly design",
              cons: "Some bugs",
            },
          ],
          socials: [],
          acceptedCountries: [
            {
              id: 96,
              text: "RO",
            },
          ],
          tags: {
            id: 2,
            paymentMethods: [
              {
                id: 10,
                name: "ATM",
                slug: "atm",
                created_at: "2021-10-12T12:50:48.086Z",
                updated_at: "2021-10-12T12:50:48.090Z",
              },
              {
                id: 9,
                name: "Payeer",
                slug: "payeer",
                created_at: "2021-10-12T12:50:41.505Z",
                updated_at: "2021-10-12T12:50:41.510Z",
              },
            ],
            currencies: [
              {
                id: 3,
                name: "EURO",
                code: "EUR",
                slug: "eur",
                created_at: "2021-10-12T12:43:39.667Z",
                updated_at: "2021-10-12T12:43:39.670Z",
              },
              {
                id: 4,
                name: "Canadian Dollar",
                code: "CAD",
                slug: "cad",
                created_at: "2021-10-12T12:43:56.025Z",
                updated_at: "2021-10-12T12:43:56.028Z",
              },
            ],
            languages: [
              {
                id: 2,
                name: "English",
                code: "EN",
                slug: "english",
                created_at: "2021-10-12T12:38:05.543Z",
                updated_at: "2021-10-12T12:38:05.548Z",
              },
              {
                id: 6,
                name: "Italian",
                code: "IT",
                slug: "italian",
                created_at: "2021-10-12T12:39:06.327Z",
                updated_at: "2021-10-12T12:39:06.330Z",
              },
            ],
            licences: [
              {
                id: 7,
                name: "Tortor",
                slug: "tortor",
                created_at: "2021-10-12T12:48:28.833Z",
                updated_at: "2021-10-12T12:48:28.836Z",
              },
            ],
            restrictedCountries: [
              {
                id: 6,
                name: "Colombia",
                slug: "colombia",
                code: "CO",
                created_at: "2021-10-12T13:30:03.330Z",
                updated_at: "2021-10-12T13:30:03.336Z",
              },
            ],
            softwares: [],
            categories: [],
          },
          features: [
            {
              id: 440,
              text: "Excepteur sint occaecat cupidatat",
            },
            {
              id: 442,
              text: "Duis aute irure dolor",
            },
            {
              id: 443,
              text: "Lorem ipsum dolor sit amet, consectetur",
            },
            {
              id: 444,
              text: "Fames ac turpis egestas maecenas",
            },
            {
              id: 441,
              text: "Dignissim cras tincidunt lobortis",
            },
          ],
          thumbnail: {
            id: 40,
            name: "cat_casino26.jpeg",
            alternativeText: "",
            caption: "",
            width: 500,
            height: 500,
            formats: {
              thumbnail: {
                name: "thumbnail_cat_casino26.jpeg",
                hash: "thumbnail_cat_casino26_fe3fd4c902",
                ext: ".jpeg",
                mime: "image/jpeg",
                width: 156,
                height: 156,
                size: 3.56,
                path: null,
                url: "images/casino-logos/cat_casino4.jpg",
              },
            },
            hash: "cat_casino26_fe3fd4c902",
            ext: ".jpeg",
            mime: "image/jpeg",
            size: 18.4,
            url: "images/casino-logos/cat_casino4.jpg",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            created_at: "2021-10-08T11:10:46.848Z",
            updated_at: "2021-10-08T11:10:46.859Z",
          },
          href: {
            name: "Bookmaker",
            params: {
              slug: "/",
            },
          },
        },
        {
          id: 2,
          title: "Bet243",
          excerpt: "New customer offer. Exchange bets excluded.",
          about:
            "About Bookmaker\nLeverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.\n\nBring to the table win-win survival strategies to ensure proactive domination.\nCapitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.Podcasting operational change management inside of workflows to establish a framework.\n\nTaking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI.",
          type: "certified",
          likes: 3,
          referralLink: "https://modeltheme.com/go/coinflip/",
          active: true,
          slug: "Bet243",
          review: null,
          published_at: "2021-10-08T12:20:52.025Z",
          created_at: "2021-10-08T12:09:34.216Z",
          updated_at: "2021-11-01T07:49:44.253Z",
          termsAndConditions: "New customer offer. Exchange bets excluded.",
          newaccountoffer: "up to $200  Equal bonus with deposit",
          offerValue: "up to $200",
          offerInfo: "Equal bonus with deposit",
          casinos: [
            {
              thumbnail: {
                url: "images/casino-logos/cat_casino4.jpg",
              },
            },
            {
              thumbnail: {
                url: "images/games/esport-betting-slot3.jpg",
              },
            },
          ],
          rating: 3,
          experiences: [
            {
              id: 149,
              pros: "Unlimited withdraw limits",
              cons: "A number of restricted countries",
            },
            {
              id: 150,
              pros: "Multiple withdraw methods",
              cons: "Slow connection sometimes",
            },
            {
              id: 151,
              pros: "Live chat open 24/7",
              cons: "Long withdraw time",
            },
            {
              id: 152,
              pros: "Mobile-friendly design",
              cons: "Some bugs",
            },
          ],
          socials: [],
          acceptedCountries: [
            {
              id: 96,
              text: "RO",
            },
          ],
          tags: {
            id: 2,
            paymentMethods: [
              {
                id: 10,
                name: "ATM",
                slug: "atm",
                created_at: "2021-10-12T12:50:48.086Z",
                updated_at: "2021-10-12T12:50:48.090Z",
              },
              {
                id: 9,
                name: "Payeer",
                slug: "payeer",
                created_at: "2021-10-12T12:50:41.505Z",
                updated_at: "2021-10-12T12:50:41.510Z",
              },
            ],
            currencies: [
              {
                id: 3,
                name: "EURO",
                code: "EUR",
                slug: "eur",
                created_at: "2021-10-12T12:43:39.667Z",
                updated_at: "2021-10-12T12:43:39.670Z",
              },
              {
                id: 4,
                name: "Canadian Dollar",
                code: "CAD",
                slug: "cad",
                created_at: "2021-10-12T12:43:56.025Z",
                updated_at: "2021-10-12T12:43:56.028Z",
              },
            ],
            languages: [
              {
                id: 2,
                name: "English",
                code: "EN",
                slug: "english",
                created_at: "2021-10-12T12:38:05.543Z",
                updated_at: "2021-10-12T12:38:05.548Z",
              },
              {
                id: 6,
                name: "Italian",
                code: "IT",
                slug: "italian",
                created_at: "2021-10-12T12:39:06.327Z",
                updated_at: "2021-10-12T12:39:06.330Z",
              },
            ],
            licences: [
              {
                id: 7,
                name: "Tortor",
                slug: "tortor",
                created_at: "2021-10-12T12:48:28.833Z",
                updated_at: "2021-10-12T12:48:28.836Z",
              },
            ],
            restrictedCountries: [
              {
                id: 6,
                name: "Colombia",
                slug: "colombia",
                code: "CO",
                created_at: "2021-10-12T13:30:03.330Z",
                updated_at: "2021-10-12T13:30:03.336Z",
              },
            ],
            softwares: [],
            categories: [],
          },
          features: [
            {
              id: 440,
              text: "Excepteur sint occaecat cupidatat",
            },
            {
              id: 442,
              text: "Duis aute irure dolor",
            },
            {
              id: 443,
              text: "Lorem ipsum dolor sit amet, consectetur",
            },
            {
              id: 444,
              text: "Fames ac turpis egestas maecenas",
            },
            {
              id: 441,
              text: "Dignissim cras tincidunt lobortis",
            },
          ],
          thumbnail: {
            id: 40,
            name: "cat_casino26.jpeg",
            alternativeText: "",
            caption: "",
            width: 500,
            height: 500,
            formats: {
              thumbnail: {
                name: "thumbnail_cat_casino26.jpeg",
                hash: "thumbnail_cat_casino26_fe3fd4c902",
                ext: ".jpeg",
                mime: "image/jpeg",
                width: 156,
                height: 156,
                size: 3.56,
                path: null,
                url: "images/casino-logos/cat_casino4.jpg",
              },
            },
            hash: "cat_casino26_fe3fd4c902",
            ext: ".jpeg",
            mime: "image/jpeg",
            size: 18.4,
            url: "images/casino-logos/cat_casino4.jpg",
            previewUrl: null,
            provider: "local",
            provider_metadata: null,
            created_at: "2021-10-08T11:10:46.848Z",
            updated_at: "2021-10-08T11:10:46.859Z",
          },
          href: {
            name: "Bookmaker",
            params: {
              slug: "/",
            },
          },
        },
      ],
    };
  },
});
